<div class="container-fluid bg-light pt-60 pb-60">
  <div class="container">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div>Copyright © {{year}} Tyrian Co</div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="mt-10">
      <div>
        <a href="tel:+971504575164">Tel. +971 50 457 5164</a>
      </div>
      <div class="ml-10 mr-10">|</div>
      <div><a href="mailto:rc@tyrianco.com">rc@tyrianco.com</a></div>
    </div>
  </div>
</div>
